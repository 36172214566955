<template>
  <div id="members-subscrition-assign">
    <v-card>
      <v-form ref="form2" v-model="valid2" lazy-validation>
        <v-card-title class="text-h5">
          <h2>Members Subscription List</h2>
        </v-card-title>

        <v-card-subtitle class="text-h5">
          <h4>
            List of pending subscription are not assigned to members
          </h4></v-card-subtitle
        >
        <v-footer :fixed="true" :absolute="false" :padless="true">
          <v-card flat tile width="100%" color="#8950FC">
            <v-container>
              <v-row wrap>
                <v-col
                  align="center"
                  cols="4"
                  sm="4"
                  md="4"
                  @click.prevent="tab = 'tab-1'"
                >
                  <h3 class="text-white">
                    Previous half Members : {{ selected1.length }}
                  </h3>
                </v-col>
                <v-col
                  align="center"
                  cols="4"
                  sm="4"
                  md="4"
                  @click.prevent="tab = 'tab-2'"
                >
                  <h3 class="text-white">
                    Old Members : {{ selected2.length }}
                  </h3>
                </v-col>
                <v-col
                  align="center"
                  cols="4"
                  sm="4"
                  md="4"
                  @click.prevent="tab = 'tab-3'"
                >
                  <h3 class="text-white">
                    New Members : {{ NewMembers.length }}
                  </h3>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row wrap>
                <v-col align="center" cols="6" md="6">
                  <h3 class="text-white">
                    Total Members : {{ TotalMembers }} /
                    {{ rows.RemainingCount }}
                  </h3>
                </v-col>
                <v-col align="center" cols="6" md="6">
                  <v-btn
                    :loading="SubmitFlag"
                    color="success"
                    class="mr-4"
                    @click="submitConfirmAlert"
                  >
                    Submit
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-footer>
        <v-container class="py-0">
          <v-speed-dial
            v-if="FloatingButtonFlag"
            v-model="fab"
            :top="top"
            :bottom="bottom"
            :right="right"
            :left="left"
            :direction="direction"
            :open-on-hover="hover"
            :transition="transition"
            :fixed="true"
          >
            <template v-slot:activator>
              <v-btn v-model="fab" color="blue darken-2" dark fab>
                <v-icon v-if="fab"> mdi-close </v-icon>
                <v-icon v-else> mdi-account-circle </v-icon>
              </v-btn>
            </template>
            <!-- <v-btn fab dark small color="green">
          <v-icon>mdi-pencil</v-icon>
        </v-btn> -->
            <v-btn
              fab
              dark
              small
              color="indigo"
              @click.prevent="addRecordPrompt = true"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <!-- <v-btn fab dark small color="red">
          <v-icon>mdi-delete</v-icon>
        </v-btn> -->
          </v-speed-dial>
        </v-container>
        <v-dialog v-model="addRecordPrompt" persistent max-width="600px">
          <add-new-member
            pageDescription="Create Staff"
            :addRecordPrompt="addRecordPrompt"
            @hideAddRecordPrompt="addNewMember($event)"
            v-if="addRecordPrompt"
          ></add-new-member>
        </v-dialog>

        <v-card-text>
          <v-container class="py-0">
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn color="success" class="mr-4" @click="backPage">
                  Back
                </v-btn>
                <!-- <v-btn color="success" class="mr-4" @click="confirmAlert">
                  Alert
                </v-btn> -->
              </v-col>
            </v-row>
          </v-container>
          <br />
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <br />
            <b-spinner
              variant="primary"
              type="grow"
              label="Loading..."
            ></b-spinner>
            <br /><br />
          </div>
          <v-container class="py-0" v-if="StartupLoadingFlag">
            <v-skeleton-loader
              v-bind="attrs"
              type="table-tbody"
            ></v-skeleton-loader>
          </v-container>
          <v-container class="py-0" v-if="!StartupLoadingFlag">
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <h2 class="title1">
                  {{ rows.YearName }} - {{ rows.ContributionTypeDescription }}
                </h2>
              </v-col>
            </v-row>
            <v-row wrap>
              <!-- <v-col align="center" cols="12" md="3">
                <v-card color="#B9004F" dark hover>
                  <v-card-title class="card-title"> Last Date </v-card-title>
                  <v-card-subtitle>
                    <p class="card-subtitle1">
                      {{ rows.ContributionEndDateTxt }}
                    </p>
                  </v-card-subtitle>
                </v-card>
              </v-col> -->

              <v-col align="center" cols="12" md="4">
                <v-card color="#ff6b6b" dark hover>
                  <v-card-title class="card-title">
                    Subscription paid
                  </v-card-title>
                  <v-card-subtitle>
                    <br /><br />
                    <p class="card-subtitle">{{ rows.PaidCount }}</p>
                  </v-card-subtitle>
                  <p class="card-title">Members</p>
                  <v-card-actions> </v-card-actions>
                </v-card>
              </v-col>

              <v-col align="center" cols="12" md="4">
                <v-card color="success" dark hover>
                  <v-card-title class="card-title">
                    Totally selected
                  </v-card-title>
                  <v-card-subtitle>
                    <br /><br />
                    <p class="card-subtitle">{{ rows.SelelctedCount }}</p>
                  </v-card-subtitle>
                  <p class="card-title">Members</p>
                  <v-card-actions> </v-card-actions>
                </v-card>
              </v-col>

              <v-col align="center" cols="12" md="4">
                <v-card color="warning" dark hover>
                  <v-card-title class="card-title">
                    Please select
                  </v-card-title>
                  <v-card-subtitle>
                    <br /><br />
                    <p class="card-subtitle">{{ rows.RemainingCount }}</p>
                  </v-card-subtitle>
                  <p class="card-title">Members</p>
                  <v-card-actions> </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>

          <br />

          <v-container class="notes">
            <p>Important notes:</p>
            <p>Dear President, the below page has three tabs.</p>
            <ol>
              <li>
                The first tab
                <v-icon icon size="25px" color="primary">
                  mdi-account-tie
                </v-icon>
                shows the previous half members.
              </li>
              <li>
                The second tab
                <v-icon icon size="25px" color="primary">
                  mdi-account-group
                </v-icon>
                shows all the old members.
              </li>
              <li>
                The third tab
                <v-icon icon size="25px" color="primary">
                  mdi-account-plus
                </v-icon>
                is used to create new members to your LOM.
              </li>
              <li>
                For the new members created, the membership ID will be
                immediately created.
              </li>
              <li>
                The total count including all the three tabs cannot exceed the
                total subscription paid Subscription for the same member cannot
                be marked twice
              </li>
              <li>
                Selecting the members can be done partially also. Eg: If you
                have paid subscription for 10 members, you can choose few
                members today and choose the remaining members later also
              </li>
            </ol>
          </v-container>

          <br />

          <v-tabs
            v-model="tab"
            background-color="#8950FC"
            centered
            dark
            icons-and-text
            show-arrows
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1" :disabled="TabDisable1">
              Previous half Members
              <v-icon>mdi-account-tie</v-icon>
            </v-tab>

            <v-tab href="#tab-2" :disabled="TabDisable2">
              Old Members
              <v-icon>mdi-account-group</v-icon>
            </v-tab>

            <v-tab href="#tab-3" :disabled="TabDisable3" v-if="rows.NewMembersFlag == 1">
              New Members
              <v-icon>mdi-account-plus</v-icon>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">
              <v-card flat>
                <p></p>
                <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                    <h3 class="text-primary">
                      Select Members from the Prervious half
                    </h3>
                  </v-col>
                </v-row>
                <v-container
                  class="py-0"
                  v-if="
                    !LoadingFlag &&
                    ResultFlag &&
                    PreviousHalfMembers.length == 0
                  "
                >
                  <br />
                  <v-row wrap>
                    <v-col align="center" cols="12" md="12">
                      <h4>No members found.</h4>
                    </v-col>
                  </v-row>
                </v-container>

                <v-container class="py-0" v-if="PreviousHalfMembers.length > 0">
                  <br />
                  <v-row wrap>
                    <v-col align="center" cols="12" md="12">
                      <h3>{{ PreviousHalfMembers.length }} members found</h3>
                    </v-col>
                  </v-row>
                  <v-row wrap>
                    <v-col align="center" cols="12" md="12">
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search records here"
                        single-line
                        hide-details
                      ></v-text-field>
                      <br />
                      <v-data-table
                        class="elevation-1"
                        v-model="selected1"
                        :headers="tableColumns1"
                        :items="PreviousHalfMembers"
                        :items-per-page="50"
                        :search="search"
                        item-key="DuesId"
                        :single-select="false"
                        :show-select="true"
                        :footer-props="{
                          'items-per-page-options': [10, 20, 30, 40, 50],
                        }"
                      >
                        <template v-slot:item.ActiveStatusTxt="{ item }">
                          <v-chip
                            :color="getActiveStatusColor(item.ActiveStatusTxt)"
                            draggable
                            dark
                            >{{ item.ActiveStatusTxt }}</v-chip
                          >
                        </template>
                        <template v-slot:item.MemberImagePath="{ item }">
                          <img
                            width="100"
                            height="100"
                            :src="item.MemberImagePath"
                          />
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="mr-2"
                                icon
                                size="25px"
                                color="blue"
                                @click="previewData(item, e)"
                                v-bind="attrs"
                                v-on="on"
                                v-if="item.ActiveStatus == 2"
                              >
                                mdi-clipboard-text-search
                              </v-icon>
                            </template>
                            <span> Preview </span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                icon
                                size="25px"
                                color="warning"
                                @click="editAlert(item, e)"
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-file-document-edit
                              </v-icon>
                            </template>
                            <span> Edit </span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab-2">
              <v-card flat>
                <p></p>
                <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                    <h3 class="text-primary">
                      Select from all other Members of the LOM
                    </h3>
                  </v-col>
                </v-row>
                <v-container
                  class="py-0"
                  v-if="
                    !LoadingFlag && ResultFlag && ExistingMembers.length == 0
                  "
                >
                  <br />
                  <v-row wrap>
                    <v-col align="center" cols="12" md="12">
                      <h4>No members found.</h4>
                    </v-col>
                  </v-row>
                </v-container>
                <v-container class="py-0" v-if="ExistingMembers.length > 0">
                  <br />
                  <v-row wrap>
                    <v-col align="center" cols="12" md="12">
                      <h3>{{ ExistingMembers.length }} members found</h3>
                    </v-col>
                  </v-row>
                  <v-row wrap v-if="ExistingMembers.length > 0">
                    <v-col align="center" cols="12" md="12">
                      <v-text-field
                        v-model="search2"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                      ></v-text-field>
                      <br />
                      <v-data-table
                        class="elevation-1"
                        v-model="selected2"
                        :headers="tableColumns2"
                        :items="ExistingMembers"
                        :items-per-page="50"
                        :search="search2"
                        :footer-props="{
                          'items-per-page-options': [10, 20, 30, 40, 50, -1],
                        }"
                        item-key="MemberId"
                        :single-select="false"
                        :show-select="true"
                      >
                        <template v-slot:item.ActiveStatusTxt="{ item }">
                          <v-chip
                            :color="getActiveStatusColor(item.ActiveStatusTxt)"
                            draggable
                            dark
                            >{{ item.ActiveStatusTxt }}</v-chip
                          >
                        </template>
                        <template v-slot:item.ProfilePic="{ item }">
                          <img
                            width="100"
                            height="100"
                            :src="item.ProfilePic"
                          />
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                icon
                                size="25px"
                                color="warning"
                                @click="editAlert(item, e)"
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-file-document-edit
                              </v-icon>
                            </template>
                            <span> Edit </span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab-3" v-if="rows.NewMembersFlag == 1">
              <v-card flat>
                <p></p>
                <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                    <h3 class="text-primary">Add New Members to the LOM</h3>
                  </v-col>
                </v-row>
                <v-container class="py-0">
                  <br />
                  <v-row wrap>
                    <v-col align="center" cols="12" md="12">
                      <br />
                      <v-simple-table class="new-member-table">
                        <template>
                          <thead>
                            <tr>
                              <th class="text-center">
                                <h2>Member Name</h2>
                              </th>
                              <th class="text-center">
                                <h2>Email</h2>
                              </th>
                              <th class="text-center">
                                <h2>Mobile No</h2>
                              </th>
                              <th class="text-center">
                                <h2>Address</h2>
                              </th>
                              <th>
                                <v-btn
                                  icon
                                  color="blue"
                                  @click.prevent="addRecordPrompt = true"
                                >
                                  <v-icon>mdi-plus-circle</v-icon>
                                </v-btn>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, k) in NewMembers" :key="k">
                              <td>
                                {{ item.MemberName }}
                                <!-- <v-text-field
                            v-model="item.MemberName"
                            :rules="MemberNameRules"
                            label="First Name"
                            required
                            outlined
                            dense
                          ></v-text-field> -->
                              </td>
                              <td>
                                {{ item.MemberEmail }}
                                <!-- <v-text-field
                            v-model="item.MemberEmail"
                            :rules="MemberEmailRules"
                            label="Email"
                            outlined
                            dense
                          ></v-text-field> -->
                              </td>
                              <td>
                                {{ item.MemberMobileNo }}
                                <!-- <v-text-field
                            v-model="item.MemberMobileNo"
                            :rules="MemberMobileNoRules"
                            label="Mobile No"
                            outlined
                            dense
                          ></v-text-field> -->
                              </td>
                              <td class="td-city">
                                <p
                                  v-html="item.MemberAddressTxt"
                                  style="font-size: 14px"
                                ></p>
                                <!-- <v-autocomplete
                            :reduce="(option) => option.value"
                            :loading="CityCodeOptionsLoading"
                            :items="CityCodeOptions"
                            :rules="CityCodeRules"
                            v-model="item.MemberCity"
                            label="City"
                            dense
                            clearable
                            required
                            outlined
                          ></v-autocomplete> -->
                              </td>
                              <td>
                                <v-btn
                                  icon
                                  color="blue"
                                  @click.prevent="addRecordPrompt = true"
                                >
                                  <v-icon>mdi-plus-circle</v-icon>
                                </v-btn>
                                <v-btn
                                  icon
                                  color="red"
                                  @click="deleteRow(2, k, item)"
                                >
                                  <v-icon>mdi-delete-circle-outline</v-icon>
                                </v-btn>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                  <br /><br />
                </v-container>
              </v-card>
            </v-tab-item>
          </v-tabs-items>

          <br /><br />
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
import AddNewMember from "@/view/pages/erp/payment/AddNewMember.vue";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {
    AddNewMember,
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      StartupLoadingFlag: false,
      FloatingButtonFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      ResultFlag: false,

      TabDisable1: false,
      TabDisable2: false,
      TabDisable3: false,

      rows: [],
      tableColumns1: [],
      tableColumns2: [],
      selected: [],
      search: "",
      search2: "",
      CurrentYearId: 0,

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,

      zone: {},
      lom: {},
      LomPaymentId: "",
      PreviousHalfMembers: [],
      ExistingMembers: [],
      Member: {},

      selected1: [],
      selected2: [],
      NewMembers: [],
      TotalMembers: 0,

      name: "",
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      select: "Item 1",
      items: ["Item 1", "Item 2", "Item 3", "Item 4"],
      checkbox: false,

      FromZoneCodeRules: [(v) => !!v || "Zone is required"],
      FromZoneCode: "",
      FromZoneCodeOptions: [],
      FromZoneCodeOptionsLoading: false,

      FromLomCodeRules: [(v) => !!v || "LOM is required"],
      FromLomCode: "",
      FromLomCodeOptions: [],
      FromLomCodeOptionsLoading: false,

      ToZoneCodeRules: [(v) => !!v || "Zone is required"],
      ToZoneCode: "",
      ToZoneCodeOptions: [],
      ToZoneCodeOptionsLoading: false,

      ToLomCodeRules: [(v) => !!v || "LOM is required"],
      ToLomCode: "",
      ToLomCodeOptions: [],
      ToLomCodeOptionsLoading: false,

      SearchId: "",
      SearchIdRules: [(v) => !!v || "Search Type is required"],
      SearchIdOptions: [
        { value: "", text: "--Select Type--" },
        { value: 1, text: "By LOM" },
        { value: 2, text: "By Membership Id" },
      ],

      MembershipId: "",
      MembershipIdRules: [(v) => !!v || "Membership Id is required"],

      YearHalfId: "",
      YearHalfIdRules: [(v) => !!v || "Year Half is required"],
      YearHalfIdOptions: [
        { value: "", text: "--Select Half--" },
        { value: "First", text: "First Half" },
        { value: "Second", text: "Second Half" },
      ],

      YearIdRules: [(v) => !!v || "Year is required"],
      YearId: "",
      YearIdOptions: [],
      YearIdOptionsLoading: false,

      ApprovalDescription: "",
      ApprovalDescriptionRules: [],

      ConfirmButtonActionPage: "",
      CancelButtonActionPage: "",

      tab: null,

      icons: ["mdi-home", "mdi-email", "mdi-calendar", "mdi-delete"],
    };
  },
  mounted() {
    /*
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Form Inputs & Control", route: "autocompletes" },
      { title: "Fileinptus" },
    ]);
    */
  },
  watch: {
    SearchId: function () {
      console.log("watch SearchId");
      this.rows = [];
    },
    selected1: function () {
      console.log("watch selected1");
      this.calculateTotalMembers();
    },
    selected2: function () {
      console.log("watch selected2");
      this.calculateTotalMembers();
    },
    NewMembers: function () {
      console.log("watch NewMembers");
      this.calculateTotalMembers();
    },
  },
  methods: {
    backPage() {
      this.$router.go(-1);
    },
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    addNewMember(member) {
      console.log("addNewMember called");
      this.addRecordPrompt = false;
      console.log("Member=" + JSON.stringify(member));
      this.Member = member;
      this.addNewRow(2);
    },
    resetForm() {
      this.search = "";
      this.rows = [];
      this.selected = [];
      this.$refs.form2.reset();
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    calculateTotalMembers() {
      console.log("calculateTotalMembers called");
      var c1 = this.selected1.length;
      var c2 = this.selected2.length;
      var c3 = this.NewMembers.length;
      console.log("c1=" + c1 + ", c2=" + c2 + ", c3=" + c3);
      var TotalMembers = parseInt(c1) + parseInt(c2) + parseInt(c3);
      console.log("TotalMembers=" + TotalMembers);
      this.TotalMembers = TotalMembers;
    },
    addNewRow(tableId) {
      console.log("addNewRow called");
      console.log("tableId=" + tableId);

      if (tableId == 2) {
        var n1 = this.NewMembers.length;
        console.log("n1=" + n1);
        var MemberFlag = this.Member.MemberFlag;
        console.log("MemberFlag=" + MemberFlag);
        if (MemberFlag) {
          this.NewMembers.push(this.Member);
        }
        // if (n1 < 30) {
        this.Member = {};
        // }
        /*
        {
          MemberName: "",
          MemberMiddleName: "",
          MemberLastName: "",
          MemberEmail: "",
          MemberMobileNo: "",
          MemberAddress: "",
          MemberCity: "",
          MemberPincode: "",
        }
        */
      }
    },
    deleteRow(tableId, index, tr) {
      console.log("deleteRow called");
      console.log(
        "tableId=" + tableId + ", index=" + index + ", tr=" + JSON.stringify(tr)
      );
      if (index > -1) {
        if (tableId == 1) {
          this.AllDesignation.splice(index, 1);
        }
        if (tableId == 2) {
          this.NewMembers.splice(index, 1);
        }
      }
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.getTableRecords();
    },
    submitConfirmAlert() {
      Swal.fire({
        title: "Do you want to continue?",
        text: "Please verify all the information before proceeding.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `Continue`,
        confirmButtonColor: "#3085d6",
        cancelButtonText: `Cancel`,
        cancelButtonColor: "#d33",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.submitForm();
        } else {
          this.sweetAlert("error", "You cancelled the process", true);
        }
      });
    },
    confirmAlert() {
      Swal.fire({
        title: "Awesome!",
        text: "Members selected successfully",
        icon: "success",
        showCancelButton: true,
        confirmButtonText: `Go to Members list`,
        confirmButtonColor: "#3085d6",
        cancelButtonText: `Go to Subscription list`,
        cancelButtonColor: "#32a852",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          // this.submitForm();
          this.$router.push(this.ConfirmButtonActionPage);
        } else {
          this.$router.push(this.CancelButtonActionPage);
          // this.sweetAlert("error", "You cancelled the process", true);
        }
      });
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var LomPaymentId = this.LomPaymentId;
      console.log("LomPaymentId=" + LomPaymentId);

      if (LomPaymentId != null) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom-payment/show";
        var upload = {
          UserInterface: 1,
          LomPaymentId: LomPaymentId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.rows = [];
        this.ResultFlag = false;
        this.LoadingFlag = true;
        this.StartupLoadingFlag = true;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.ResultFlag = true;
            thisIns.LoadingFlag = false;
            thisIns.StartupLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.rows = records;
              thisIns.getPreviousHalfMembers();
              thisIns.getAllMembers();
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.toast("error", error);
            thisIns.ResultFlag = true;
            thisIns.LoadingFlag = false;
            thisIns.StartupLoadingFlag = false;
          });
      } else {
        var message = "LOM should not be empty.";
        console.log("error=" + message);
      }
    },
    getPreviousHalfMembers() {
      console.log("getPreviousHalfMembers called");

      //   this.resetMessageTxt();
      this.selected1 = [];
      this.PreviousHalfMembers = [];

      var LomCode = this.rows.LomId;
      var YearCode = this.rows.YearId;
      var YearHalf = this.rows.YearHalf;
      var MemberType = this.rows.MemberTypeId;
      console.log(
        "LomCode=" +
          LomCode +
          ", YearCode=" +
          YearCode +
          ", YearHalf=" +
          YearHalf +
          ", MemberType=" +
          MemberType
      );

      if (LomCode != "" && YearCode != "" && YearHalf != "") {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/member-dues/lists";
        var upload = {
          UserInterface: 1,
          LomCode: LomCode,
          YearCode: YearCode,
          YearHalf: YearHalf,
          MemberType: MemberType,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        // thisIns.pageLoadingOn()
        this.LoadingFlag = true;

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.SuccessMessageTxtFlag = 1;
              thisIns.SuccessMessageTxt = output;
              thisIns.PreviousHalfMembers = records;
              thisIns.NewMembersFlag = true;
            } else {
              thisIns.ErrorMessageTxtFlag = 1;
              thisIns.ErrorMessageTxt = output;
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        this.ErrorMessageTxtFlag = 1;
        this.ErrorMessageTxt = "LOM should not be empty.";
      }
    },
    getAllMembers() {
      console.log("getAllMembers called");

      // this.resetMessageTxt();
      this.selected2 = [];
      this.ExistingMembers = [];

      var LomCode = this.rows.LomId;
      var YearCode = this.rows.YearId;
      var YearHalf = this.rows.YearHalf;
      var MemberType = this.rows.MemberTypeId;
      console.log(
        "LomCode=" +
          LomCode +
          ", YearCode=" +
          YearCode +
          ", YearHalf=" +
          YearHalf +
          ", MemberType=" +
          MemberType
      );

      if (LomCode != "" && YearCode != "" && YearHalf != "") {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members";
        var upload = {
          UserInterface: 2,
          additional: {
            LomCode: LomCode,
            YearCode: YearCode,
            YearHalf: YearHalf,
            MemberTypeId: MemberType,
          },
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        // thisIns.pageLoadingOn()
        thisIns.ProgessStart = 1;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.ProgessStart = 0;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.SuccessMessageTxtFlag = 1;
              thisIns.SuccessMessageTxt = output;
              thisIns.ExistingMembers = records;
              thisIns.NewMembersFlag = true;
            } else {
              thisIns.ErrorMessageTxtFlag = 1;
              thisIns.ErrorMessageTxt = output;
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
          });
      } else {
        this.ErrorMessageTxtFlag = 1;
        this.ErrorMessageTxt = "LOM should not be empty.";
      }
    },
    getZoneCodeOptions(source, destination) {
      console.log("getZoneCodeOptions called");
      // this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      var CurrentYearId = this.CurrentYearId;
      console.log(
        "source=" +
          source +
          ", destination=" +
          destination +
          ", CurrentYearId=" +
          CurrentYearId
      );
      if (source != "" && destination != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/year-wise-lom/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: CurrentYearId,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getLomCodeOptions(source, destination, ZoneCode) {
      console.log("getLomCodeOptions called");
      console.log(
        "source=" +
          source +
          ", destination=" +
          destination +
          ", ZoneCode=" +
          ZoneCode
      );
      var CurrentYearId = this.CurrentYearId;
      if (ZoneCode != "" && CurrentYearId != "") {
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          YearCode: CurrentYearId,
          ZoneCode: ZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "Zone should not be empty";
        this.toast("error", message);
      }
    },
    getYearCodeOptions(source, destination) {
      console.log("getYearCodeOptions called");
      // this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      // var CurrentYearId = this.CurrentYearId;
      var CurrentYearId = 1;
      console.log(
        "source=" +
          source +
          ", destination=" +
          destination +
          ", CurrentYearId=" +
          CurrentYearId
      );
      if (source != "" && destination != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/jci-year/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    searchForm() {
      console.log("searchForm is called");

      var validate1 = this.$refs.form2.validate();
      var SearchId = this.SearchId;
      var UserInterface = SearchId == 1 ? 1 : 3;
      console.log(
        "validate1=" +
          validate1 +
          ", SearchId=" +
          SearchId +
          ", UserInterface=" +
          UserInterface
      );

      if (validate1) {
        this.SearchFlag = true;
        this.rows = [];
        this.zone = {};
        this.lom = {};

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members";
        var upload = {
          UserInterface: UserInterface,
          additional: {
            MembershipId: this.MembershipId,
            LomCode: this.FromZoneCode,
            LomCode: this.FromLomCode,
          },
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SearchFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.toast("success", output);
              thisIns.rows = records;
              thisIns.zone = response.data.zone;
              thisIns.lom = response.data.lom;
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.toast("error", message);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form2.validate();
      console.log("validate1=" + validate1);

      var RemainingCount = this.rows.RemainingCount;
      console.log("RemainingCount=" + RemainingCount);

      var PreviousMembers = this.selected1;
      var n1 = PreviousMembers.length;
      console.log(
        "n1=" + n1 + ", PreviousMembers=" + JSON.stringify(PreviousMembers)
      );

      var NewMembers = this.NewMembers;
      var n2 = NewMembers.length;
      console.log("n2=" + n2 + ", NewMembers=" + JSON.stringify(NewMembers));

      var ExistingMembers = this.selected2;
      var n3 = ExistingMembers.length;
      console.log(
        "n3=" + n3 + ", ExistingMembers=" + JSON.stringify(ExistingMembers)
      );

      var TotalCount = parseInt(n1) + parseInt(n2) + parseInt(n3);
      console.log("TotalCount=" + TotalCount);

      // this.rows = [];

      if (validate1 && TotalCount <= RemainingCount && RemainingCount > 0) {
        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom-payment/assign-members";
        var upload = {
          LomPaymentId: this.LomPaymentId,
          PreviousMembers: PreviousMembers,
          ExistingMembers: ExistingMembers,
          NewMembers: NewMembers,
          TotalMembers: TotalCount,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.toast("success", output);
              thisIns.confirmAlert();
              // thisIns.$router.push("/lom/membership-list");
            } else {
              // thisIns.toast("error", output);
              thisIns.sweetAlert("info", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        if (TotalCount > RemainingCount && RemainingCount > 0) {
          message +=
            "You can select or create within " +
            RemainingCount +
            " members only. ";
        }
        if (RemainingCount == 0) {
          message += "Already you selected all members for this subscription. ";
        }
        this.sweetAlert("error", message, false);
        // this.toast("error", message);
      }
    },
  },
  beforeMount() {
    console.log(this.$route.query.id);
    this.LomPaymentId = this.$route.query.id;
    var CurrentYearId = this.$session.get("CurrentYearId");
    CurrentYearId = 1;
    CurrentYearId = CurrentYearId == (null || undefined) ? 0 : CurrentYearId;

    var RoleId = this.$session.get("RoleId");
    RoleId = RoleId == (null || undefined) ? 0 : RoleId;

    if (RoleId == (1 || 2 || 4)) {
      this.ConfirmButtonActionPage = "/members/list";
      this.CancelButtonActionPage = "/lom/subscription/list";
    } else {
      this.ConfirmButtonActionPage = "/lom/membership-list";
      this.CancelButtonActionPage = "/members/subscription/list";
    }

    this.CurrentYearId = CurrentYearId;
    this.refreshPageData();
    this.tableColumns1.push(
      { text: "#", value: "sno" },
      { text: "Photo", value: "MemberImagePath" },
      { text: "Member Id", value: "MembershipId" },
      { text: "Member Name", value: "MemberName" },
      { text: "Current Half", value: "DuesPaidTxt" },
      { text: "Mobile No.", value: "MobileNo" },
      { text: "Email", value: "EmailId" },
      { text: "Address", value: "AddressTxt" }
      //   { text: "Actions", value: "actions" }
    );
    this.tableColumns2.push(
      { text: "#", value: "sno" },
      { text: "Photo", value: "ProfilePic" },
      { text: "Member Id", value: "MembershipId" },
      { text: "Member Name", value: "MemberName" },
      { text: "Current Half", value: "DuesPaidTxt" },
      { text: "Mobile No.", value: "MobileNo" },
      { text: "Email", value: "EmailId" },
      { text: "Address", value: "AddressTxt" }
      // { text: "Actions", value: "actions" }
    );
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#members-subscrition-assign {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }

  .card-title {
    font-size: 25px;
    font-family: "Lora", serif;
    padding: 20px;
    text-align: center;
    word-wrap: break-word;
  }
  .card-subtitle {
    font-size: 60px;
    font-family: "Lobster", cursive;
    color: white;
    text-align: center;
  }
  .card-subtitle1 {
    font-size: 25px;
    font-family: "Libre Baskerville", serif;
    color: white;
    text-align: center;
    line-height: 40px;
  }

  .title1 {
    font-weight: bold;
    font-family: "Libre Baskerville", serif;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
  }
  .notes {
    color: black;
    font-size: 16px;
    font-weight: 400;
    line-height: 2;
  }
}
</style>